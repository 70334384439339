<template>
<b-card no-body>
  <validation-observer v-slot="{ handleSubmit }" ref="VForm">
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col lg="4">
            <b-form-group>
              <label>Name (ID)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mc_name_id" placeholder="e.g Honda"></b-form-input>                  
              <VValidate 
                name="Name (ID)" 
                v-model="row.mc_name_id" 
                :rules="mrValidation.mc_name_id" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <label>Name (EN)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mc_name_en" placeholder="e.g Honda"></b-form-input>                  
              <VValidate 
                name="Name (EN)" 
                v-model="row.mc_name_en" 
                :rules="mrValidation.mc_name_en" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <label>Name (JP)<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mc_name_jp" placeholder="e.g ホンダ"></b-form-input>                  
              <VValidate 
                name="Name (JP)" 
                v-model="row.mc_name_jp" 
                :rules="mrValidation.mc_name_jp" 
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <label>Link</label>
              <b-form-input v-model="row.mc_link"></b-form-input>                  
              <VValidate 
                name="Link" 
                v-model="row.mc_link" 
                :rules="mrValidation.mc_link" 
              />
            </b-form-group>
          </b-col>

          <div class="w-100"></div>

          <b-col lg="4">
            <div class="file-uploader">
              <label>Image <span class="text-danger mr5">*</span></label>
              <Uploader v-model="row.mc_image" type="client"/>
              <VValidate 
                name="Image" 
                v-model="row.mc_image" 
                :rules="{required:1}" 
              />
            </div>
          </b-col>

          <div class="w-100"></div>

          <b-col lg="6">
            <b-form-group class="mt-3">
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row[$parent.statusKey]"
              />
              <VValidate 
                name="Status" 
                v-model="row.mc_is_active" 
                :rules="{required:1}" 
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded" @click="$parent.doSubmitCRUD('VForm', $refs)">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrMenuRel:Array,
  },
  data() {
    return {
    }
  },
  computed:{
    isEmptyHakAkses(){
      if(!(this.row.bul_menu_role||[]).length) return true
      const dtEmpty = _.filter(this.row.bul_menu_role, v=>{
        return !Object.keys(v).length
      })
      return !!dtEmpty.length
    }
  },
  methods:{
    doSubmitForm(){
      this.$emit('click:doSubmit', 'VForm', this.$refs)
    },
    isEqual(p1, p2){
      if(p1 == p2) return true
      return false
    },
    setOverflowAuto(){
      document.querySelector('.page-wrapper').style.overflow = 'auto'
    },
    setOverflowHidden(){
      document.querySelector('.page-wrapper').style.overflow = 'hidden'
    },
  }
}
</script>